import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ProductSchema } from '../../../constants/schema/yup_schema';
import { addProduct, updateProduct } from '../../../services/product_service';
import { getCategory } from '../../../services/category_service';
import { SuccessToast, ErrorToast } from "../../../constants/toast/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "../../../components/dropdown/Dropdown";
import CustomSubmitButton from "./CustomAddButton";
import image3 from '../../../assets/images/home-image-3.svg';
import { uploadPhoto } from '../../../helpers/Helpers';

function ProductAdd() {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedPhotoId, setSelectedPhotoId] = useState();

    const { id } = useParams();
    const navigate = useNavigate();

    const location = useLocation();
    const { product } = location.state || {};

    useEffect(() => {
        getCategories();
    }, []);

    const productUpdate = async (e, { setSubmitting }) => {
        try {
            await updateProduct(id, e.productname, e.productprice, e.productdescription, e.productcontents, selectedPhotoId);
            SuccessToast("Güncelleme Başarılı");
            setTimeout(() => {
                navigate(-1);
            }, 1500);
        } catch (error) {
            ErrorToast(error.message || "Güncelleme Başarısız");
        } finally {
            setSubmitting(false);
        }
    };

    const handleFileChange = async (event) => {
        const photoId = await uploadPhoto(event.target.files[0]);
        setSelectedPhotoId(photoId);
    };

    const getCategories = async () => {
        try {
            const result = await getCategory();
            setCategories(result);
            if (result.length > 0) {
                setSelectedCategory(parseInt(result[0].id));
            }
        } catch (error) {
            ErrorToast("Kategori çekilirken hata.");
        }
    }

    const handleCategoryChange = (selectedCategory) => {
        setSelectedCategory(selectedCategory);
    };

    const productAdd = async (e, { setSubmitting }) => {
        try {
            await addProduct(e.productname, e.productprice, e.productcontents, selectedCategory, e.productdescription, selectedPhotoId);
            SuccessToast("Ekleme Başarılı");
        } catch (error) {
            ErrorToast(error.message || "Ekleme Başarısız");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <section
            className="bg-cover bg-center h-auto"
            style={{ backgroundImage: `url(${image3})` }}
        >
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                <div className="w-full bg-white rounded-lg shadow  sm:max-w-md xl:p-0 my-12">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        {
                            id !== undefined
                                ? (
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                        Ürün Güncelle
                                    </h1>)
                                : (
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                        Ürün Ekle
                                    </h1>)
                        }
                        <Formik
                            initialValues={id !== undefined
                                ? { productname: product.product_name, productprice: product.product_price, productcontents: product.product_contents, productcategory: product.product_category, productdescription: product.product_description }
                                : { productname: '', productprice: '', productcontents: '', productcategory: '', productdescription: '' }
                            }
                            validationSchema={ProductSchema}
                            onSubmit={id !== undefined
                                ? productUpdate
                                : productAdd
                            }
                        >
                            <Form className="space-y-4 md:space-y-6">
                                <div>
                                    <label htmlFor="productname" className="block mb-2 text-sm font-medium text-gray-900">
                                        Ürün Adı
                                    </label>
                                    <Field
                                        type="text"
                                        name="productname"
                                        id="productname"
                                        placeholder="Ürün adı"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    />
                                    <ErrorMessage name="productname" component="div" className="text-red-500 text-sm" />
                                </div>
                                <div>
                                    <label htmlFor="productprice" className="block mb-2 text-sm font-medium text-gray-900">
                                        Ürün Fiyatı
                                    </label>
                                    <Field
                                        type="text"
                                        name="productprice"
                                        id="productprice"
                                        placeholder="Ürün Fiyatı"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    />
                                    <ErrorMessage name="productprice" component="div" className="text-red-500 text-sm" />
                                </div>
                                <div>
                                    <label htmlFor="productcontents" className="block mb-2 text-sm font-medium text-gray-900">
                                        Ürün İçeriği
                                    </label>
                                    <Field
                                        type="text"
                                        name="productcontents"
                                        id="productcontents"
                                        placeholder="Ürün İçeriği"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    />
                                    <ErrorMessage name="productcontents" component="div" className="text-red-500 text-sm" />
                                </div>
                                {
                                    id === undefined ? (
                                        <Dropdown values={categories} onCategoryChange={handleCategoryChange} />
                                    ) : (null)
                                }
                                <div>
                                    <label htmlFor="productdescription" className="block mb-2 text-sm font-medium text-gray-900">
                                        Ürün Açıklaması
                                    </label>
                                    <Field
                                        type="text"
                                        name="productdescription"
                                        id="productdescription"
                                        placeholder="Ürün Açıklaması"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    />
                                    <ErrorMessage name="productdescription" component="div" className="text-red-500 text-sm" />
                                </div>
                                <div>
                                    <label htmlFor="productphoto" className="block mb-2 text-sm font-medium text-gray-900">
                                        Ürün Fotoğrafı
                                    </label>
                                    <div className="flex row">
                                        <input type="file"
                                            name="productphoto"
                                            id="productphoto"
                                            placeholder="Ürün Fotoğrafı"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>
                                {id !== undefined ? (
                                    <div className="flex justify-center">
                                        <CustomSubmitButton label={"Güncelle"} />
                                    </div>) : (
                                    <div className="flex justify-center">
                                        <CustomSubmitButton label={"Ekle"} />
                                    </div>)
                                }

                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </section>
    );
};

export default ProductAdd;
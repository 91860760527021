import React, { useEffect, useState } from "react";
import { Card } from "./components/Card";
import { getColorCategory } from "../../services/color_category_service";

function ProjectCategory() {

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const result = await getColorCategory();
        console.log(result);
        setCategories(result);
    }

    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-4 sm:px-8 md:px-12 mt-4 sm:mt-8 md:mt-12 mb-12">
                {categories.map((category) => (
                    <Card key={category.id} category={category} />
                ))}
            </div>

        </>
    );
};

export default ProjectCategory;
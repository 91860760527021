import * as yup from 'yup';

export const LoginSchema = yup.object().shape({
    username: yup
        .string()
        .required('Kullanıcı adı zorunludur!'),
    password: yup
        .string()
        .typeError('Her karakteri kullanamazsiniz!')
        .min(4, 'Şifreniz 4 karakterden az olamaz!')
        .max(20, 'Şifreniz 20 karakterden fazla olamaz!')
        .required('Şifre alani zorunludur!'),
});

export const CategorySchema = yup.object().shape({
    categoryname: yup
        .string()
        .required('Kategori Adı zorunludur!'),
});

export const ColorSchema = yup.object().shape({
    colorname: yup
        .string()
        .required('Renk Adı zorunludur!'),
});

export const ProductSchema = yup.object().shape({
    productname: yup
        .string()
        .required('Ürün Adı zorunludur!'),
    productprice: yup
        .string()
        .required('Ürün Fiyatı zorunludur!'),
    productcontents: yup
        .string()
        .required('Ürün İçeriği zorunludur!'),
    productdescription: yup
        .string()
        .required('Ürün Açıklaması zorunludur!'),
});

export const ReferenceSchema = yup.object().shape({
    referencename: yup
        .string()
        .required('Referans Adı zorunludur!'),
});

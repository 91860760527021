import { SuccessToast, ErrorToast } from "../constants/toast/toast";
import { addPhoto } from '../services/photo_service';

const arrayBufferToBase64 = (buffer) => {
    var base64Flag = 'data:image/jpeg;base64,';
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    var resultPhoto = base64Flag + window.btoa(binary);

    return resultPhoto;
}

const uploadPhoto = async (selectedFile) => {
    try {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await addPhoto(formData);
        SuccessToast("Fotoğraf Yükleme Başarılı");
        return (response);
    } catch (error) {
        ErrorToast(error.message || "Fotoğraf Ekleme Başarısız");
    }
};

export { uploadPhoto, arrayBufferToBase64 };
import axios, { URL } from '../constants/api/axios';

const token = localStorage.getItem('auth_token');

export const getColors = async () => {
    try {
        const result = await axios.get(URL.getColor);
        return result.data;
    } catch (error) {
        throw new Error("Renkleri getirme Başarısız");
    }
}

export const getColorByCategory = async (category_id) => {
    try {
        const result = await axios.get(URL.getColorById + category_id);
        return result.data;
    } catch (error) {
        throw new Error("Renk getirme Başarısız");
    }
}

export const addColor = async (color_name, category_id, photo_url) => {
    await axios.post(
        URL.addColor,
        {
            color_name: color_name,
            category_id: category_id,
            photo_url: photo_url
        },
        {
            headers:
            {
                'Authorization': token
            }
        },
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Renk Ekleme Başarısız");
        });
};

export const updateColor = async (id, color_name, category_id, photo_url) => {
    let data = { id: id, color_name: color_name, category_id: category_id };
    if (photo_url !== null && photo_url !== undefined)
        data.photo_url = photo_url;

    await axios.put(
        URL.updateColor + id,
        data,
        {
            headers:
            {
                'Authorization': token
            }
        },
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Renk Güncelleme Başarısız");
        });
};

export const deleteColor = async (id) => {
    await axios.delete(
        URL.deleteColor + id,
        {
            headers:
            {
                'Authorization': token
            }
        },
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Renk Silme Başarısız");
        });
};
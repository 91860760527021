import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ColorSchema } from '../../../constants/schema/yup_schema';
import { SuccessToast, ErrorToast } from "../../../constants/toast/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomSubmitButton from "./CustomAddButton";
import image7 from '../../../assets/images/home-image-7.svg';
import { uploadPhoto } from '../../../helpers/Helpers';
import { addColor, updateColor } from "../../../services/color_service";
import Dropdown from "../../../components/dropdown/Dropdown";
import { getColorCategory } from "../../../services/color_category_service";

function ProjectAdd() {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState();
    const location = useLocation();
    const { color } = location.state || {};

    const { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        getColorCategories();
    }, []);

    const getColorCategories = async () => {
        try {
            const result = await getColorCategory();
            setCategories(result);
            if (result.length > 0) {
                setSelectedCategory(parseInt(result[0].id));
            }
        } catch (error) {
            ErrorToast("Kategori çekilirken hata.");
        }
    }

    const handleCategoryChange = (selectedCategory) => {
        setSelectedCategory(selectedCategory);
    };

    const colorUpdate = async (e, { setSubmitting }) => {
        try {
            await updateColor(id, e.colorname, selectedCategory, selectedPhotoUrl);
            SuccessToast("Güncelleme Başarılı");
            setTimeout(() => {
                navigate(-1);
            }, 1500);
        } catch (error) {
            ErrorToast(error.message || "Güncelleme Başarısız");
        } finally {
            setSubmitting(false);
        }
    };

    const handleFileChange = async (event) => {
        const photoUrl = await uploadPhoto(event.target.files[0]);
        setSelectedPhotoUrl(photoUrl);
    };

    const colorAdd = async (e, { setSubmitting }) => {
        try {
            await addColor(e.colorname, selectedCategory, selectedPhotoUrl);
            SuccessToast("Ekleme Başarılı");
        } catch (error) {
            ErrorToast(error.message || "Ekleme Başarısız");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <section
            className="bg-cover bg-center h-screen"
            style={{ backgroundImage: `url(${image7})` }}
        >
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        {
                            id !== undefined
                                ? (
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                        Renk Güncelle
                                    </h1>)
                                : (
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                        Renk Ekle
                                    </h1>)
                        }
                        <Formik
                            initialValues={id !== undefined
                                ? { colorname: color.color_name, colorphoto: '' }
                                : { colorname: '', colorphoto: '' }
                            }
                            validationSchema={ColorSchema}
                            onSubmit={id !== undefined ? colorUpdate : colorAdd}
                        >
                            <Form className="space-y-4 md:space-y-6">
                                <div>
                                    <label htmlFor="categoryname" className="block mb-2 text-sm font-medium text-gray-900">
                                        Renk Adı
                                    </label>
                                    <Field
                                        type="text"
                                        name="colorname"
                                        id="colorname"
                                        placeholder="Renk adı"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    />
                                    <ErrorMessage name="categoryname" component="div" className="text-red-500 text-sm" />
                                </div>
                                {
                                    id === undefined ? (
                                        <Dropdown values={categories} onCategoryChange={handleCategoryChange} />
                                    ) : (null)
                                }
                                <div>
                                    <label htmlFor="colorphoto" className="block mb-2 text-sm font-medium text-gray-900">
                                        Renk Fotoğrafı
                                    </label>
                                    <div className="flex row">
                                        <input type="file"
                                            name="colorphoto"
                                            id="colorphoto"
                                            placeholder="Renk Fotoğrafı"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>
                                {
                                    id !== undefined
                                        ? (
                                            <div className="flex justify-center">
                                                <CustomSubmitButton label={"Güncelle"} />
                                            </div>)
                                        : (
                                            <div className="flex justify-center">
                                                <CustomSubmitButton label={"Ekle"} />
                                            </div>)
                                }
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </section>
    );
};

export default ProjectAdd;
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { deleteProduct } from '../../services/product_service';
import CustomDeleteButton from '../../components/button/CustomDeleteButton';
import CustomUpdateButton from '../../components/button/CustomUpdateButton';
import { SuccessToast, ErrorToast } from "../../constants/toast/toast";
import { ToastContainer } from "react-toastify";
import { arrayBufferToBase64 } from "../../helpers/Helpers";
import NoPhoto from "../../assets/images/no-photo.png";
import { tokenIsValid } from "../../services/auth_service"

function ProductDetail() {
    const { id } = useParams();
    const navigate = useNavigate();

    const location = useLocation();
    const { product } = location.state || {};

    const [token, setToken] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        tokenControl();
    }, [])

    const tokenControl = async () => {
        const isValid = await tokenIsValid(localStorage.getItem('auth_token'));
        setToken(isValid);
    }

    const detailDelete = async () => {
        try {
            await deleteProduct(product.id);
            SuccessToast('Ürün başarılı şekilde silindi.');

            setTimeout(() => {
                navigate(-1);
            }, 1500);

        } catch (error) {
            ErrorToast(error || 'Ürün silme başarısız.');
        }
    }

    const detailUpdate = () => {
        navigate('/product/update/' + id, { state: { product } })
    }
    return (
        <>
            <div className="flex flex-col lg:flex-row lg:justify-evenly lg:mx-36 lg:my-16 mx-4 my-8 p-8 border border-gray-200">
                {
                    product.photo_data != null ? (
                        <img className="rounded-lg w-full lg:w-5/12"
                            src={arrayBufferToBase64(product.photo_data.data)}
                            alt={product.product_name}
                            onClick={openModal}
                        />
                    ) : (
                        <img className="rounded-lg w-full lg:w-5/12"
                            src={NoPhoto}
                            alt={product.product_name}
                            onClick={openModal}
                        />
                    )
                }
                {isModalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="absolute inset-0 bg-gray-900 opacity-75" onClick={closeModal}></div>
                        <div className="relative">
                            <button className="absolute top-0 right-0 m-2 text-black text-xl" onClick={closeModal}>
                                X
                            </button>
                            <img
                                className="object-contain h-screen w-screen"
                                src={product.photo_data != null ? arrayBufferToBase64(product.photo_data.data) : NoPhoto}
                                alt={product.product_name}
                            />
                        </div>
                    </div>
                )}

                <div className="flex flex-col justify-evenly mt-4">
                    {
                        token ?
                            (
                                <div className="flex justify-between">
                                    <CustomDeleteButton onClick={() => detailDelete()} label={'Sil'} />
                                    <CustomUpdateButton onClick={() => detailUpdate()} label={'Güncelle'} />
                                </div>
                            )
                            :
                            null
                    }
                    <p className="text-2xl lg:text-5xl font-semibold tracking-tight text-gray-900">
                        {product.product_name}
                    </p>
                    <p className="text-m lg:text-2xl tracking-tight text-gray-900 mt-2">
                        {product.product_description}
                    </p>
                    <p className="text-sm lg:text-l tracking-tight text-gray-900 mt-2">
                        {product.product_contents}
                    </p>
                    {
                        parseInt(product.product_price) === 0
                            ? (null)
                            : (<p className="text-3xl font-bold tracking-tight text-gray-900 mt-4">{product.product_price} ₺</p>)
                    }
                </div>
            </div>

            <ToastContainer />
        </>
    );
};

export default ProductDetail;
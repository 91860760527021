import axios, { URL } from '../constants/api/axios';

export const addColorCategory = async (category_name, photo_url) => {
    const token = localStorage.getItem('auth_token');

    await axios.post(
        URL.addColorCategory,
        {
            category_name: category_name,
            photo_url: photo_url,
        },
        {
            headers: {
                'Authorization': token,
            }
        }
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Ekleme Başarısız");
        });
};

export const getColorCategory = async () => {
    try {
        const result = await axios.get(URL.getColorCategory);
        return result.data;
    } catch (error) {
        throw new Error("Kategori Getirme Başarısız");
    }
};

export const getColorCategoryById = async (id) => {
    try {
        const result = await axios.get(URL.getColorCategoryById + id);
        return result.data;
    } catch (error) {
        throw new Error("Kategori Getirme Başarısız");
    }
};

export const updateColorCategory = async (id, category_name, photo_url) => {
    const token = localStorage.getItem('auth_token');
    let data = { id: id, category_name: category_name };
    if (photo_url !== null && photo_url !== undefined)
        data.photo_url = photo_url;

    await axios.put(
        URL.updateColorCategory + id,
        data,
        {
            headers:
            {
                'Authorization': token
            }
        },
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Güncelleme Başarısız");
        });
};

export const deleteColorCategory = async (id) => {
    const token = localStorage.getItem('auth_token');

    await axios.delete(
        URL.deleteColorCategory + id,
        {
            headers:
            {
                'Authorization': token
            }
        },
    ).then((response) => {
        return response;
    },)
        .catch((error) => {
            throw new Error("Silme Başarısız");
        });
};
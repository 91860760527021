import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteCategory } from "../../../services/category_service";
import CustomDetailButton from "../../../components/button/CustomDetailButton";
import CustomDeleteButton from "../../../components/button/CustomDeleteButton";
import CustomUpdateButton from "../../../components/button/CustomUpdateButton";
import { arrayBufferToBase64 } from "../../../helpers/Helpers";
import NoPhoto from "../../../assets/images/no-photo.png";
import { tokenIsValid } from "../../../services/auth_service"

export function Card({ category }) {

    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [deleted, setDeleted] = useState(false);

    useEffect(() => {
        tokenControl();
    }, [])

    const tokenControl = async () => {
        const isValid = await tokenIsValid(localStorage.getItem('auth_token'));
        setToken(isValid);
    }

    const getData = (category) => {
        navigate('/product_category/' + category.category_name.toLowerCase() + '=' + category.id);
    }

    const updateCard = (id) => {
        navigate('/product_category/' + category.category_name.toLowerCase() + '/update/' + id, { state: { category } });
    }

    const deleteCard = async (id) => {
        await deleteCategory(id);
        setDeleted(true);
    }

    if (deleted)
        return null;

    return (
        <>
            <div className="border rounded-lg p-4 w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow">
                {token ? (
                    <div className="flex justify-between">
                        <CustomDeleteButton onClick={() => deleteCard(category.id)} label={'Sil'} />
                        <CustomUpdateButton onClick={() => updateCard(category.id)} label={'Güncelle'} />
                    </div>
                ) : null}
                {category.photo_data != null ? (
                    <img className="p-8 rounded-t-lg object-cover w-full h-auto sm:h-64 md:h-48 lg:h-56 xl:h-64" src={arrayBufferToBase64(category.photo_data.data)} alt={category.category_name} />
                ) : (
                    <img className="p-8 rounded-t-lg object-cover w-full h-auto sm:h-64 md:h-48 lg:h-56 xl:h-64" src={NoPhoto} alt={category.category_name} />
                )}

                <div className="px-5 pb-5">
                    <h5 className="flex items-center justify-center text-2xl font-bold tracking-tight text-gray-900">{category.category_name}</h5>
                </div>
                <div className="flex justify-center">
                    <CustomDetailButton onClick={() => getData(category)} label='Ürünlere Git' />
                </div>
            </div>
        </>
    );
}
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home/Home';
import About from '../pages/about/About';
import Contact from '../pages/contact/Contact';
import Add from '../pages/add/Add';
import Login from '../pages/login/Login';
import Product from '../pages/products/Product';
import ProductCategory from '../pages/products/ProductCategory';
import ProductDetail from '../pages/products/ProductDetail';
import Color from '../pages/color/Color';
import ColorCategory from '../pages/color/ColorCategory';
import Project from '../pages/project/Project';
import ProjectCategory from '../pages/project/ProjectCategory';

function Router() {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/login' element={<Login />} />
            <Route path='/add/:name' element={<Add />} />
            <Route path='/:name/:category_name/update/:id' element={<Add />} />
            <Route path='/:name/update/:id' element={<Add />} />
            <Route path='/product_category' element={<ProductCategory />} />
            <Route path='/color_category' element={<ColorCategory />} />
            <Route path='/project_category' element={<ProjectCategory />} />
            <Route path='/product_category/:category_name' element={<Product />} />
            <Route path='/color_category/:category_name' element={<Color />} />
            <Route path='/project_category/:category_name' element={<Project />} />
            <Route path='/product/detail/:id' element={<ProductDetail />} />
        </Routes>
    );
};

export default Router;
import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import Logo from '../../assets/images/Logo.svg';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { tokenIsValid } from '../../services/auth_service';
import CustomDeleteButton from '../button/CustomDeleteButton';

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [token, setToken] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            closeDropdown(event);
        };

        tokenControl();

        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const tokenControl = async () => {
        const isValid = await tokenIsValid(localStorage.getItem('auth_token'));
        setToken(isValid);
    }

    function logout() {
        localStorage.removeItem('auth_token');
        window.location.reload();
    }

    return (
        <header className="bg-white">
            <div className="mx-auto max-w-7xl p-6 lg:px-8 flex justify-between items-center">
                <div className="flex items-center">
                    <Link to={"/"}>
                        <span className="sr-only">Alımlı Mobilya</span>
                        <img className="h-12 w-auto" src={Logo} alt="Alımlı Mobilya" />
                    </Link>
                </div>
                <div className="hidden lg:flex items-center space-x-12 ">
                    <Link to="/" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800
                    {{ activePage === 'anasayfa' ? 'font-bold' : '' }}">
                        Ana Sayfa
                    </Link>

                    <Link to="/product_category" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800">
                        Ürünler
                    </Link>

                    <Link to="/project_category" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800">
                        Bitmiş Projeler
                    </Link>

                    <Link to="/color_category" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800">
                        Renk Kataloğu
                    </Link>

                    <Link to={"/about"} className='text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800'>
                        Hakkımızda
                    </Link>

                    <Link to="/contact" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800">
                        İletişim
                    </Link>
                    {token ? (
                        <>
                            <div className="relative" ref={dropdownRef}>
                                <button onClick={toggleDropdown} type="button" className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" aria-expanded={isOpen ? 'true' : 'false'}>
                                    Ekle
                                    <svg className="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                    </svg>
                                </button>

                                {isOpen && (
                                    <div className="absolute -left-8 top-full z-10 mt-3 w-max max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5" onClick={closeDropdown}>
                                        <div className="p-4">
                                            <div className="group relative flex flex-col gap-y-2">
                                                <Link to="/add/product" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800">
                                                    Ürün Ekle
                                                </Link>
                                                <Link to="/add/product_category" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800">
                                                    Kategori Ekle
                                                </Link>
                                                <Link to="/add/reference" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800">
                                                    Referans Ekle
                                                </Link>
                                                <Link to="/add/color" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800">
                                                    Renk Ekle
                                                </Link>
                                                <Link to="/add/colorcategory" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800">
                                                    Renk Kategorisi Ekle
                                                </Link>
                                                <Link to="/add/project" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800">
                                                    Proje Ekle
                                                </Link>
                                                <Link to="/add/projectcategory" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                                    Proje Kategorisi Ekle
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : null}
                    {token ? (
                        <CustomDeleteButton label={'Çıkış Yap'} onClick={logout} />
                    ) : null}
                </div>
                <div className="lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-yellow-800"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </div>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Alımlı Mobilya</span>
                            <img className="h-8 w-auto" src={Logo} alt="" />
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6">
                        <nav className="grid gap-y-4">
                            <Link to="/" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                Ana Sayfa
                            </Link>
                            <Link to="/product_category" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                Ürünler
                            </Link>
                            <Link to="/project_category" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                Bitmiş Projeler
                            </Link>
                            <Link to="/color_category" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                Renk Kataloğu
                            </Link>
                            <Link to="/about" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                Hakkımızda
                            </Link>
                            <Link to="/contact" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                İletişim
                            </Link>

                            {token ? (
                                <>
                                    <div className="relative" ref={dropdownRef}>
                                        <button onClick={toggleDropdown} type="button" className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" aria-expanded={isOpen ? 'true' : 'false'}>
                                            Ekle
                                            <svg className="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                            </svg>
                                        </button>

                                        {isOpen && (
                                            <div className="p-4" onClick={closeDropdown}>
                                                <div className="group relative flex flex-col gap-y-2">
                                                    <Link to="/add/product" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                                        Ürün Ekle
                                                    </Link>
                                                    <Link to="/add/product_category" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                                        Kategori Ekle
                                                    </Link>
                                                    <Link to="/add/reference" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                                        Referans Ekle
                                                    </Link>
                                                    <Link to="/add/color" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                                        Renk Ekle
                                                    </Link>
                                                    <Link to="/add/colorcategory" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                                        Renk Kategorisi Ekle
                                                    </Link>
                                                    <Link to="/add/project" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                                        Proje Ekle
                                                    </Link>
                                                    <Link to="/add/projectcategory" className="text-sm font-semibold leading-6 text-gray-900 hover:text-yellow-800" onClick={() => setMobileMenuOpen(false)}>
                                                        Proje Kategorisi Ekle
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : null}
                            {token ? (
                                <CustomDeleteButton label={'Çıkış Yap'} onClick={logout} />
                            ) : null}
                        </nav>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    );
}

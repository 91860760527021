import React from "react";
import CategoryAdd from "./components/CategoryAdd";
import ProductAdd from "./components/ProductAdd";
import ReferenceAdd from "./components/ReferenceAdd";
import { useParams } from "react-router-dom";
import ColorAdd from "./components/ColorAdd";
import ColorCategoryAdd from "./components/ColorCategoryAdd";
import ProjectAdd from "./components/ProjectAdd";
import ProjectCategoryAdd from "./components/ProjectCategoryAdd";

function Add() {
    const { name } = useParams();
    return (
        <>
            {
                name === 'product_category' ? (<CategoryAdd />) :
                    name === 'product' ? (<ProductAdd />) :
                        name === 'reference' ? (<ReferenceAdd />) :
                            name === 'color' ? (<ColorAdd />) :
                                name === 'color_category' ? (<ColorCategoryAdd />) :
                                    name === 'project' ? (<ProjectAdd />) :
                                        name === 'project_category' ? (<ProjectCategoryAdd />) :
                                            (<></>)
            }
        </>
    );
};

export default Add;
import axios, { URL } from '../constants/api/axios';

export const addPhoto = async (photo_data) => {
    try {
        const response = await axios.post(
            URL.addPhotoFirebase,
            photo_data
        );
        return response.data.photoUrl;
    } catch (error) {
        console.error('Error uploading photo:', error);
        throw new Error("Ekleme Başarısız");
    }
};


export const getPhotoById = async (id) => {
    try {
        const result = await axios.get(URL.getPhotoById + id);
        return result.data;
    } catch (error) {
        throw new Error("Fotoğraf Getirme Başarısız");
    }
};